.visibility-hidden {
  visibility: hidden;
}

.m-h-300 {
  min-height: 300px;
}

.color-red {
  color: red;
}

.no-border {
  border: none !important;
}

.l-h-36 {
  line-height: 36px;
}

.display-inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
  pointer-events: inherit;
}

.visually-hidden {
  width: 0;
  height: 0;
  border: none;
  visibility: hidden;
}
