@charset "UTF-8";
/*! angularjs-slider - v5.5.0 -  (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervieu.me>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> -  https://github.com/angular-slider/angularjs-slider -  2016-09-06 */
@import url(https://fonts.googleapis.com/earlyaccess/nanumgothic.css);
/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 35px 0 15px 0;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.with-legend {
  margin-bottom: 40px; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider[disabled] {
  cursor: not-allowed; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-base {
  width: 100%;
  height: 100%;
  padding: 0; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-bar-wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 32px;
  padding-top: 16px;
  margin-top: -16px;
  box-sizing: border-box; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-bar-wrapper.rz-draggable {
  cursor: move; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
  background: #d8e0f3;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-bar.rz-selection {
  z-index: 2;
  background: #0db9f0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-pointer {
  top: -14px;
  z-index: 3;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #0db9f0;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-pointer:after {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 8px;
  height: 8px;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  content: ''; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-pointer:hover:after {
  background-color: #fff; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-pointer.rz-active {
  z-index: 4; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-pointer.rz-active:after {
  background-color: #451aff; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-bubble {
  bottom: 16px;
  padding: 1px 3px;
  color: #55637d;
  cursor: default; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-bubble.rz-selection {
  top: 16px; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-bubble.rz-limit {
  color: #55637d; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-ticks {
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 0;
  padding: 0 11px;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-ticks .rz-tick {
  width: 10px;
  height: 10px;
  text-align: center;
  cursor: pointer;
  background: #d8e0f3;
  border-radius: 50%; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-ticks .rz-tick.rz-selected {
  background: #0db9f0; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-ticks .rz-tick .rz-tick-value {
  position: absolute;
  top: -30px;
  transform: translate(-50%, 0); }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-ticks .rz-tick .rz-tick-legend {
  position: absolute;
  top: 24px;
  max-width: 50px;
  white-space: normal;
  transform: translate(-50%, 0); }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider .rz-ticks.rz-ticks-values-under .rz-tick-value {
  top: initial;
  bottom: -40px; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical {
  position: relative;
  width: 4px;
  height: 100%;
  padding: 0;
  margin: 0 20px;
  vertical-align: baseline; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-base {
  width: 100%;
  height: 100%;
  padding: 0; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-bar-wrapper {
  top: auto;
  left: 0;
  width: 32px;
  height: 100%;
  padding: 0 0 0 16px;
  margin: 0 0 0 -16px; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-bar {
  bottom: 0;
  left: auto;
  width: 4px;
  height: 100%; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-pointer {
  top: auto;
  bottom: 0;
  left: -14px !important; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-bubble {
  bottom: 0;
  left: 16px !important;
  margin-left: 3px; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-bubble.rz-selection {
  top: auto;
  left: 16px !important; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-ticks {
  top: 0;
  left: -3px;
  z-index: 1;
  width: 0;
  height: 100%;
  padding: 11px 0;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-ticks .rz-tick {
  vertical-align: middle; }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-ticks .rz-tick .rz-tick-value {
  top: initial;
  left: 22px;
  transform: translate(0, -28%); }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-ticks .rz-tick .rz-tick-legend {
  top: initial;
  right: 24px;
  max-width: none;
  white-space: nowrap;
  transform: translate(0, -28%); }

/* line 2, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angularjs-slider/dist/rzslider.min.css */
.rzslider.rz-vertical .rz-ticks.rz-ticks-values-under .rz-tick-value {
  right: 12px;
  bottom: initial;
  left: initial; }

@CHARSET "ISO-8859-1";
/* line 3, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angular-http-loading-interceptor/spinner.css */
#httpLoadingSpinner {
  width: 200px;
  height: 60px;
  text-align: center;
  font-size: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -100px;
  z-index: 1100;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  -o-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
  display: none; }

/* line 22, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angular-http-loading-interceptor/spinner.css */
#httpLoadingSpinner.loading-visible {
  opacity: 1;
  display: block; }

/* line 27, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angular-http-loading-interceptor/spinner.css */
#httpLoadingSpinner > div {
  background-color: #333;
  border: 1px solid #444;
  height: 100%;
  width: 7px;
  display: inline-block;
  margin-right: 3px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out; }

/* line 38, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angular-http-loading-interceptor/spinner.css */
#httpLoadingSpinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

/* line 43, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angular-http-loading-interceptor/spinner.css */
#httpLoadingSpinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

/* line 48, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angular-http-loading-interceptor/spinner.css */
#httpLoadingSpinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

/* line 53, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/node_modules/angular-http-loading-interceptor/spinner.css */
#httpLoadingSpinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

/*
 * Nanum Gothic (Korean) http://www.google.com/fonts/earlyaccess
 */
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NanumGothic-Regular.eot);
  src: url(../fonts/NanumGothic-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/NanumGothic-Regular.woff2) format("woff2"), url(../fonts/NanumGothic-Regular.woff) format("woff"), url(../fonts/NanumGothic-Regular.ttf) format("truetype"); }

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NanumGothic-Bold.eot);
  src: url(../fonts/NanumGothic-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/NanumGothic-Bold.woff2) format("woff2"), url(../fonts/NanumGothic-Bold.woff) format("woff"), url(../fonts/NanumGothic-Bold.ttf) format("truetype"); }

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/NanumGothic-ExtraBold.eot);
  src: url(../fonts/NanumGothic-ExtraBold.eot?#iefix) format("embedded-opentype"), url(../fonts/NanumGothic-ExtraBold.woff2) format("woff2"), url(../fonts/NanumGothic-ExtraBold.woff) format("woff"), url(../fonts/NanumGothic-ExtraBold.ttf) format("truetype"); }

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NanumBarunGothic.eot);
  src: url(../fonts/NanumBarunGothic.eot?#iefix) format("embedded-opentype"), url(../fonts/NanumBarunGothic.woff) format("woff"), url(../fonts/NanumBarunGothic.ttf) format("truetype"); }

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NanumBarunGothicBold.eot);
  src: url(../fonts/NanumBarunGothicBold.eot?#iefix) format("embedded-opentype"), url(../fonts/NanumBarunGothicBold.woff) format("woff"), url(../fonts/NanumBarunGothicBold.ttf) format("truetype"); }

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/NanumBarunGothicBold.eot);
  src: url(../fonts/NanumBarunGothicBold.eot?#iefix) format("embedded-opentype"), url(../fonts/NanumBarunGothicBold.woff) format("woff"), url(../fonts/NanumBarunGothicBold.ttf) format("truetype"); }

/* line 67, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_typography.scss */
body,
h1,
h2,
h3,
h4,
h5,
h6,
.nav-tabs a {
  font-family: 'Nanum Gothic'; }

/* line 1, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_utility.scss */
.visibility-hidden {
  visibility: hidden; }

/* line 5, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_utility.scss */
.m-h-300 {
  min-height: 300px; }

/* line 9, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_utility.scss */
.color-red {
  color: red; }

/* line 13, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_utility.scss */
.no-border {
  border: none !important; }

/* line 17, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_utility.scss */
.l-h-36 {
  line-height: 36px; }

/* line 21, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_utility.scss */
.display-inline-block {
  display: inline-block; }

/* line 25, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_utility.scss */
.display-none {
  display: none; }

/* line 29, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_utility.scss */
.cursor-pointer {
  cursor: pointer;
  pointer-events: inherit; }

/* line 34, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/_utility.scss */
.visually-hidden {
  width: 0;
  height: 0;
  border: none;
  visibility: hidden; }

/* angular-http-loading-interceptor/spinner 수정 시작 */
/* line 8, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#httpLoadingSpinner {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 0;
  margin-left: 0; }

/* line 17, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#httpLoadingSpinner > div {
  height: 60px;
  margin-top: 400px; }

/*#codeModalLoadingSpinner {
  text-align: center;
  font-size: 10px;
  position: fixed;
  z-index: 1100;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
  display: none;

  width: 100%;
  height: 100%;
  top:0;
  left:0;
  margin-top:0;
  margin-left:0;
}

#codeModalLoadingSpinner > div {
  background-color: #333;
  border: 1px solid #444;
  width: 7px;
  display: inline-block;
  margin-right: 3px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;

  height: 60px;
  margin-top: 400px;
}

#codeModalLoadingSpinner.loading-visible {
  opacity: 1;
  display: block;
}*/
/* angular-http-loading-interceptor/spinner 수정 끝 */
/* line 63, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
body.fs-11 {
  font-size: 11px; }

/* line 67, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
body.fs-12 {
  font-size: 12px; }

/* line 71, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
body.fs-13 {
  font-size: 13px; }

/* line 75, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
body.fs-14 {
  font-size: 14px; }

/* line 79, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
body.fs-15 {
  font-size: 15px; }

/* line 83, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
body.fs-16 {
  font-size: 16px; }

/* line 87, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
body.fs-17 {
  font-size: 17px; }

/* line 91, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
body.fs-18 {
  font-size: 18px; }

/* line 99, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.topbar .topbar-left .logo {
  line-height: 70px; }

/* line 103, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.footer {
  text-align: center !important; }

/* line 107, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
table.dataTable thead > tr > th {
  white-space: nowrap; }

/* line 111, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
table.dataTable thead > tr > th .checkbox {
  padding-left: 0; }

/* line 115, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
table.dataTable thead > tr > th.no-sorting {
  padding-right: 8px; }

/* line 119, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.dataTables_scrollBody table.dataTable thead .sorting:after,
table.dataTable thead .no-sorting.sorting:after,
table.dataTable thead .no-sorting.sorting_asc:after,
table.dataTable thead .no-sorting.sorting_desc:after {
  display: none; }

/* line 130, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.no-layout #wrapper {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden; }

/* line 137, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.no-layout .rzslider span {
  visibility: hidden !important; }

/* line 141, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.no-layout .slimScrollDiv {
  display: none; }

/* line 145, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.account-pages {
  background: none; }

/* line 149, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.standard-input {
  width: 150px !important; }

/* line 153, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.quickmenu-btn {
  color: #ccc; }

/* line 157, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.quickmenu-btn.enabled {
  color: #10c469; }

/* line 161, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.quickmenu-icon {
  margin-top: 10px;
  margin-left: 10px;
  width: 80px;
  text-align: center;
  vertical-align: top; }

/* line 169, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.quickmenu-icon a {
  display: block;
  color: inherit; }

/* line 174, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.quickmenu-icon div {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 12px;
  border: 1px solid #ccc; }

/* line 182, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.quickmenu-icon i {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 50px; }

/* line 190, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.quickmenu-icon p {
  margin-top: 10px;
  line-height: 16px;
  color: #555; }

/* line 196, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.code-list-container {
  max-height: 500px;
  overflow-y: auto; }

/* line 201, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.sweet-alert h2 {
  font-size: 16px; }

/* line 205, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.proof-pdf-iframe {
  width: 100%;
  height: 500px;
  border: none; }

/* line 212, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.proof-pdf-page {
  width: 100%;
  height: 500px;
  border: none;
  overflow: auto;
  zoom: 1; }

/* line 220, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.proof-pdf-page canvas {
  display: block; }

/* line 224, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.rstatus- .file-link, .tdname1- a {
  display: none; }

/* line 228, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.rstatus-X .no-link, .tdname1- a {
  display: none; }

/* line 232, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
a.no-link {
  cursor: default;
  color: inherit; }

/* line 237, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#page-login {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white; }
  /* line 243, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  #page-login .wrapper-page {
    width: 1220px;
    height: 695px;
    position: relative; }
  /* line 250, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  #page-login .mission-container {
    background-image: url("../images/main-all.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 867px; }
    /* line 260, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
    #page-login .mission-container img.logo {
      margin-top: 30px;
      margin-left: 40px; }
    /* line 265, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
    #page-login .mission-container img.circle {
      position: absolute;
      top: 170px;
      left: 110px; }
    /* line 271, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
    #page-login .mission-container .mission-desc-box {
      position: absolute;
      top: 80px;
      right: 100px; }
    /* line 278, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
    #page-login .mission-container .mission-desc-wrap p {
      margin: 0 0 3px; }
    /* line 282, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
    #page-login .mission-container .mission-desc-wrap .mission-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px; }
  /* line 289, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  #page-login .login-container {
    background-image: url("../images/login-bg.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 341px; }
    /* line 299, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
    #page-login .login-container .login-title {
      margin-top: 50px; }
    /* line 303, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
    #page-login .login-container button {
      background-color: #005ca8;
      color: white;
      margin-top: 10px;
      padding: 10px; }
    /* line 310, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
    #page-login .login-container .input-wrap {
      position: relative;
      width: 250px;
      margin: 0 auto 7px; }
      /* line 316, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
      #page-login .login-container .input-wrap img {
        position: absolute;
        top: 8px;
        left: 10px; }
    /* line 323, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
    #page-login .login-container input {
      padding-left: 33px;
      border-radius: 0; }
  /* line 329, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  #page-login .theme-color {
    color: #4e80ff; }

/* line 337, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
table.table-editable.dataTable input.form-control {
  width: 100%; }

/* line 341, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
table.table-editable.dataTable .code-wrap {
  position: relative; }

/* line 346, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.datepicker + span {
  cursor: pointer; }

/* line 351, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.table-cursor tbody tr {
  cursor: pointer; }

/* line 356, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#toast-container {
  top: 50px; }

/* line 361, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
table.dataTable th,
table.dataTable td {
  white-space: nowrap; }

/* line 367, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
div.dataTables_info {
  padding-top: 10px;
  text-align: right; }

/* line 372, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
div.dataTables_paginate {
  text-align: left; }

/* line 376, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
div.dataTables_filter {
  margin-top: 30px; }

/* line 380, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.table-wrap {
  width: 100%;
  overflow-x: auto; }

/* line 385, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.table-wrap table {
  table-layout: fixed; }
  /* line 388, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  .table-wrap table td {
    word-wrap: break-word; }

/* line 393, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.table > thead:first-child > tr:first-child > th {
  vertical-align: middle; }

/* line 397, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#wrapper.enlarged .logo img {
  display: none; }

/* line 401, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
td.dataTables_empty {
  text-align: center; }

/* line 405, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.action-btn-container.float {
  position: absolute;
  top: 20px;
  left: 700px;
  z-index: 1; }

/* line 412, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
input[type="email"].ng-invalid.ng-dirty, input[type="password"].ng-invalid.ng-dirty, input[type="number"].ng-invalid.ng-dirty, input[type="text"].ng-invalid.ng-dirty {
  border-color: #a94442; }

/* line 416, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
form .help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #a94442;
  font-size: 12px; }

/* line 424, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.code-wrap .form-control-feedback {
  right: 15px; }

/* line 428, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#sidebar-menu > ul > li > a .icon {
  margin-left: 5px;
  margin-right: 17px;
  width: 16px; }

/* line 434, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#wrapper.enlarged #sidebar-menu > ul > li > a .icon {
  margin-left: 3px;
  width: 20px; }

@media (min-width: 1600px) {
  /* line 440, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  .fixed-width-search-form {
    width: 1200px; } }

@media (max-width: 1599px) {
  /* line 446, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  .fixed-width-search-form {
    width: 800px; } }

@media (max-width: 768px) {
  /* line 452, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  .logo img {
    display: none !important; } }

/* line 458, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.panel-maypage > .panel-body {
  padding-bottom: 0; }

/* line 462, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.panel-maypage .panel-maypage-small {
  margin-bottom: 20px;
  margin-right: 10px; }
  /* line 466, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  .panel-maypage .panel-maypage-small .panel-heading {
    padding: 5px 30px 0; }
  /* line 470, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  .panel-maypage .panel-maypage-small .panel-body {
    padding: 10px 10px 5px;
    text-align: center; }
  /* line 475, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  .panel-maypage .panel-maypage-small span.T_APPR-count {
    font-size: 30px;
    font-weight: bold;
    text-decoration: underline; }
  /* line 481, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  .panel-maypage .panel-maypage-small span.T_AREQ-count {
    font-size: 30px;
    font-weight: bold; }
  /* line 486, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
  .panel-maypage .panel-maypage-small p.empty-text {
    text-align: center;
    margin-bottom: 20px; }

/* table style override */
/* line 495, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #dcdcdc; }

/* line 499, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#sidebar-menu,
#sidebar-menu ul,
#sidebar-menu li,
#sidebar-menu a {
  font-weight: bold; }

/* line 506, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
tbody {
  color: #666; }

/* line 510, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
table.dataTable thead .sorting:after {
  opacity: 0.4; }

/* line 514, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
th {
  color: #555; }

/* line 518, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.form-control {
  border: 1px solid #bbb; }

/* line 522, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
tr.default-cursor {
  cursor: default !important; }

/* line 526, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.datepicker th.prev, .datepicker th.next, .datepicker th.datepicker-switch {
  cursor: pointer; }

/* line 531, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.multi-input-group input {
  padding-right: 0 !important; }

/* line 535, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.multi-input-group span {
  background: #bbb;
  cursor: pointer; }

/* line 540, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.reset-pw-btn {
  cursor: pointer;
  margin-top: 10px;
  text-align: center; }

/* line 546, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#loading-dim {
  display: none; }

/*#loading-dim.active {
  z-index: 2000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  opacity: 0.3;
}*/
/* line 562, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
#httpLoadingSpinner > div {
  background-color: black; }

/* line 566, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.quick-img {
  width: 100%; }

/* line 570, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.dataTables_scrollBody table.dataTable thead .sorting_asc:after {
  display: none; }

/* line 574, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.dataTables_scrollBody table.dataTable thead .sorting_desc:after {
  display: none; }

/* line 578, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
.zoom-wrap > div {
  cursor: pointer; }

/* line 582, D:/eclipse_test/workspace/HSMA/webroot/WEB-frontend/app/styles/main.scss */
body {
  min-width: 1200px;
  overflow: auto; }
