@import '../../node_modules/angularjs-slider/dist/rzslider.min';
@import '../../node_modules/angular-http-loading-interceptor/spinner';
@import 'typography';
@import 'utility';
@import 'vars';

/* angular-http-loading-interceptor/spinner 수정 시작 */
#httpLoadingSpinner {
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  margin-top:0;
  margin-left:0;
}

#httpLoadingSpinner > div {
  height: 60px;
  margin-top: 400px;
}

/*#codeModalLoadingSpinner {
  text-align: center;
  font-size: 10px;
  position: fixed;
  z-index: 1100;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
  display: none;

  width: 100%;
  height: 100%;
  top:0;
  left:0;
  margin-top:0;
  margin-left:0;
}

#codeModalLoadingSpinner > div {
  background-color: #333;
  border: 1px solid #444;
  width: 7px;
  display: inline-block;
  margin-right: 3px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;

  height: 60px;
  margin-top: 400px;
}

#codeModalLoadingSpinner.loading-visible {
  opacity: 1;
  display: block;
}*/
/* angular-http-loading-interceptor/spinner 수정 끝 */

body {

}

body.fs-11 {
  font-size: 11px;
}

body.fs-12 {
  font-size: 12px;
}

body.fs-13 {
  font-size: 13px;
}

body.fs-14 {
  font-size: 14px;
}

body.fs-15 {
  font-size: 15px;
}

body.fs-16 {
  font-size: 16px;
}

body.fs-17 {
  font-size: 17px;
}

body.fs-18 {
  font-size: 18px;
}

.topbar .topbar-left {

}

.topbar .topbar-left .logo {
  line-height: 70px;
}

.footer {
  text-align: center !important;
}

table.dataTable thead > tr > th {
  white-space: nowrap;
}

table.dataTable thead > tr > th .checkbox {
  padding-left: 0;
}

table.dataTable thead > tr > th.no-sorting {
  padding-right: 8px;
}

.dataTables_scrollBody table.dataTable thead .sorting:after,
table.dataTable thead .no-sorting.sorting:after,
table.dataTable thead .no-sorting.sorting_asc:after,
table.dataTable thead .no-sorting.sorting_desc:after {
  display: none;
}

table.table-bordered.dataTable {
  //border-collapse: collapse !important;
}

.no-layout #wrapper {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}

.no-layout .rzslider span {
  visibility: hidden !important;
}

.no-layout .slimScrollDiv {
  display: none;
}

.account-pages {
  background: none;
}

.standard-input {
  width: 150px !important;
}

.quickmenu-btn {
  color: #ccc;
}

.quickmenu-btn.enabled {
  color: #10c469;
}

.quickmenu-icon {
  margin-top: 10px;
  margin-left: 10px;
  width: 80px;
  text-align: center;
  vertical-align: top;
}

.quickmenu-icon a {
  display: block;
  color: inherit;
}

.quickmenu-icon div {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 12px;
  border: 1px solid #ccc;
}

.quickmenu-icon i {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 50px;
}

.quickmenu-icon p {
  margin-top: 10px;
  line-height: 16px;
  color: #555;
}

.code-list-container {
  max-height: 500px;
  overflow-y: auto;
}

.sweet-alert h2 {
  font-size: 16px;
}

.proof-pdf-iframe {

  width: 100%;
  height: 500px;
  border: none;
}

.proof-pdf-page {
  width: 100%;
  height: 500px;
  border: none;
  overflow: auto;
  zoom: 1;
}

.proof-pdf-page canvas {
  display: block;
}

.rstatus- .file-link, .tdname1- a {
  display: none;
}

.rstatus-X .no-link, .tdname1- a {
  display: none;
}

a.no-link {
  cursor: default;
  color: inherit;
}

#page-login {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;

  .wrapper-page {
    width: 1220px;
    height: 695px;

    position: relative;
  }

  .mission-container {
    background-image: url("../images/main-all.png");
    background-repeat: no-repeat;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 867px;

    img.logo {
      margin-top: 30px;
      margin-left: 40px;
    }

    img.circle {
      position: absolute;
      top: 170px;
      left:110px;
    }

    .mission-desc-box {
      position: absolute;
      top: 80px;
      right: 100px;
    }

    .mission-desc-wrap {
      p {
        margin: 0 0 3px;
      }

      .mission-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
  .login-container {
    background-image: url("../images/login-bg.png");
    background-repeat: no-repeat;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 341px;

    .login-title {
      margin-top: 50px;
    }

    button {
      background-color: #005ca8;
      color: white;
      margin-top: 10px;
      padding: 10px;
    }

    .input-wrap {
      position: relative;
      width: 250px;

      margin: 0 auto 7px;

      img {
        position: absolute;
        top: 8px;
        left: 10px;
      }
    }

    input {
      padding-left: 33px;
      border-radius: 0;
    }
  }

  .theme-color {
    color: #4e80ff;
  }
}

table.table-editable.dataTable {
  //border-right: 2px solid #ccc;

  input.form-control {
    width: 100%;
  }

  .code-wrap {
    position: relative;
  }
}

.datepicker + span {
  cursor: pointer;
}

.table-cursor {
  tbody tr {
    cursor: pointer;
  }
}

#toast-container {
  top: 50px;
}

table.dataTable {
  th,
  td {
    white-space: nowrap;
  }
}

div.dataTables_info {
  padding-top: 10px;
  text-align: right;
}

div.dataTables_paginate {
  text-align: left;
}

div.dataTables_filter {
  margin-top: 30px;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

.table-wrap table {
  table-layout: fixed;

  td {
    word-wrap: break-word;
  }
}

.table>thead:first-child>tr:first-child>th {
  vertical-align: middle;
}

#wrapper.enlarged .logo img {
  display: none;
}

td.dataTables_empty {
  text-align: center;
}

.action-btn-container.float {
  position: absolute;
  top: 20px;
  left: 700px;
  z-index: 1;
}

input[type="email"].ng-invalid.ng-dirty, input[type="password"].ng-invalid.ng-dirty, input[type="number"].ng-invalid.ng-dirty, input[type="text"].ng-invalid.ng-dirty {
  border-color: #a94442;
}

form .help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #a94442;
  font-size: 12px;
}

.code-wrap .form-control-feedback {
  right: 15px;
}

#sidebar-menu > ul > li > a .icon {
  margin-left: 5px;
  margin-right: 17px;
  width: 16px;
}

#wrapper.enlarged #sidebar-menu > ul > li > a .icon {
  margin-left: 3px;
  width: 20px;
}

@media (min-width: 1600px)  {
  .fixed-width-search-form {
    width: 1200px;
  }
}

@media (max-width: 1599px)  {
  .fixed-width-search-form {
    width: 800px;
  }
}

@media (max-width: 768px)  {
  .logo img {
    display: none !important;
  }
}

.panel-maypage {
  & > .panel-body {
    padding-bottom: 0;
  }

  .panel-maypage-small {
    margin-bottom: 20px;
    margin-right: 10px;

    .panel-heading {
      padding: 5px 30px 0;
    }

    .panel-body {
      padding: 10px 10px 5px;
      text-align: center;
    }

    span.T_APPR-count {
      font-size: 30px;
      font-weight: bold;
      text-decoration: underline;
    }

    span.T_AREQ-count {
      font-size: 30px;
      font-weight: bold;
    }

    p.empty-text {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

/* table style override */

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #dcdcdc;
}

#sidebar-menu,
#sidebar-menu ul,
#sidebar-menu li,
#sidebar-menu a {
  font-weight: bold;
}

tbody {
  color: #666;
}

table.dataTable thead .sorting:after {
  opacity: 0.4;
}

th {
  color: #555;
}

.form-control {
  border: 1px solid #bbb;
}

tr.default-cursor {
  cursor: default !important;
}

.datepicker th.prev, .datepicker th.next, .datepicker th.datepicker-switch {
  cursor: pointer;
}

.multi-input-group {
  input {
    padding-right: 0 !important;
  }

  span {
    background: #bbb; cursor: pointer;
  }
}

.reset-pw-btn {
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

#loading-dim {
  display: none;
}

/*#loading-dim.active {
  z-index: 2000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  opacity: 0.3;
}*/

#httpLoadingSpinner > div {
  background-color: black;
}

.quick-img {
  width: 100%;
}

.dataTables_scrollBody table.dataTable thead .sorting_asc:after {
  display: none;
}

.dataTables_scrollBody table.dataTable thead .sorting_desc:after {
  display: none;
}

.zoom-wrap > div {
  cursor: pointer;
}

body {
  min-width: 1200px;
  overflow: auto;
}
