/*
 * Nanum Gothic (Korean) http://www.google.com/fonts/earlyaccess
 */
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NanumGothic-Regular.eot);
  src: url(../fonts/NanumGothic-Regular.eot?#iefix) format('embedded-opentype'),
  url(../fonts/NanumGothic-Regular.woff2) format('woff2'),
  url(../fonts/NanumGothic-Regular.woff) format('woff'),
  url(../fonts/NanumGothic-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NanumGothic-Bold.eot);
  src: url(../fonts/NanumGothic-Bold.eot?#iefix) format('embedded-opentype'),
  url(../fonts/NanumGothic-Bold.woff2) format('woff2'),
  url(../fonts/NanumGothic-Bold.woff) format('woff'),
  url(../fonts/NanumGothic-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/NanumGothic-ExtraBold.eot);
  src: url(../fonts/NanumGothic-ExtraBold.eot?#iefix) format('embedded-opentype'),
  url(../fonts/NanumGothic-ExtraBold.woff2) format('woff2'),
  url(../fonts/NanumGothic-ExtraBold.woff) format('woff'),
  url(../fonts/NanumGothic-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NanumBarunGothic.eot);
  src: url(../fonts/NanumBarunGothic.eot?#iefix) format('embedded-opentype'),
  url(../fonts/NanumBarunGothic.woff) format('woff'),
  url(../fonts/NanumBarunGothic.ttf) format('truetype');
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NanumBarunGothicBold.eot);
  src: url(../fonts/NanumBarunGothicBold.eot?#iefix) format('embedded-opentype'),
  url(../fonts/NanumBarunGothicBold.woff) format('woff'),
  url(../fonts/NanumBarunGothicBold.ttf) format('truetype');
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/NanumBarunGothicBold.eot);
  src: url(../fonts/NanumBarunGothicBold.eot?#iefix) format('embedded-opentype'),
  url(../fonts/NanumBarunGothicBold.woff) format('woff'),
  url(../fonts/NanumBarunGothicBold.ttf) format('truetype');
}

@import url(https://fonts.googleapis.com/earlyaccess/nanumgothic.css);

body,
h1,
h2,
h3,
h4,
h5,
h6,
.nav-tabs a {
  font-family: 'Nanum Gothic';
}
